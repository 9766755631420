
import { defineComponent } from 'vue';
import Footer from '../../../components/Frontend/Footer.vue';

import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Offenlegung | Styria Media Group',
      keywords: ['Offenlegung', 'imprint', 'impressum', 'styria media group', 'styria'],
      description: 'Offenlegung. Styria Media Group AG, Gadollaplatz 1 8010 Graz',
      image: require('@/assets/img/og/startseite.png'),
    },
    en: {
      title: 'Disclosure | Styria Media Group',
      keywords: ['disclosure', 'styria media group', 'styria'],
      description: 'disclosure. Styria Media Group AG, Gadollaplatz 1 8010 Graz',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
